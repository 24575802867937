import React from 'react';
import { PageProps } from 'gatsby';
import { WithTranslation, withTranslation, Trans } from 'react-i18next';

import Layout, { LayoutOptions, HeroSectionColors } from '../../../../components/Layout/Layout';
import { LocaleNameSpaceKeys } from '../../../../localization/translations';
import PageMenu from '../../../../components/PageMenu/PageMenu';
import residentialMortgagesMenu from '../../../../data/page-menus/residential-mortgages';
import ExternalLink from '../../../../components/ExternalLink/ExternalLink';
import CommonData from '../../../../data/common/common';
import ButtonLink from '../../../../components/ButtonLink/ButtonLink';
import { IconTypes } from '../../../../components/Icon/Icon';
import { getPathFromRouteKey } from '../../../../shared/utils/getPathFromRouteKey';
import RouteKeys from '../../../../../config/RouteKeys';

import './style.scss';

type Props = PageProps & WithTranslation;

const RefinancingMortgage: React.FC<Props> = (props) => {
  const { t } = props;

  const layoutOptions: LayoutOptions = {
    seo: {
      title: t(`refinancingMortgage.seo.title`),
    },
    page: {
      title: t(`refinancingMortgage.heading`),
    },
    heroSection: {
      options: {
        accentColor: HeroSectionColors.SKY_BLUE,
        heroImage: {
          fileName: 'hero-image-12.jpg',
          alt: 'Image',
        },
      },
    },
  };

  return (
    <Layout options={layoutOptions} className="RefinancingMortgage">
      <div className="grid-container">
        <div className="row row-wrap">
          <section className="column column-60">
            <div className="RefinancingMortgage__generic-gap">
              <p>
                <Trans
                  t={t}
                  i18nKey="refinancingMortgage.body"
                  values={{
                    specialistEmail: CommonData.tollFreeCustomerServiceNumber.mortgageServicing,
                  }}
                >
                  <ExternalLink
                    href={`tel:${CommonData.tollFreeCustomerServiceNumber.mortgageServicing}`}
                    aria-label={`${t(`${LocaleNameSpaceKeys.COMMON}:tollFree`)} ${
                      CommonData.tollFreeCustomerServiceNumber.mortgageServicing
                    }`}
                  >
                    {CommonData.tollFreeCustomerServiceNumber.mortgageServicing}
                  </ExternalLink>
                </Trans>
              </p>
            </div>
            <div className="RefinancingMortgage__generic-gap">
              <ButtonLink
                to={getPathFromRouteKey(
                  RouteKeys.CUSTOMER_CENTRE.RESIDENTIAL_MORTGAGES.REFINANCING_MORTGAGE.FORM
                )}
                iconOptions={{
                  icon: IconTypes.ARROW_NEXT,
                }}
                styleOptions={{
                  isInline: true,
                  invertColors: true,
                }}
              >
                {t('refinancingMortgage.startRefinancing.buttonLabel')}
              </ButtonLink>
              `
            </div>
          </section>
          <aside className="column column-33">
            <PageMenu
              menu={residentialMortgagesMenu}
              menuTitle={t(`${LocaleNameSpaceKeys.COMMON}:pageMenuTitles.residentialMortgages`)}
            />
          </aside>
        </div>
      </div>
    </Layout>
  );
};

export default withTranslation(LocaleNameSpaceKeys.CUSTOMER_CENTRE)(RefinancingMortgage);
